<script lang="ts">
	import Spinner from './Spinner.svelte';

	export let loading = false;
	export let size: 'zero' | 'tiny' | 'small' | 'medium' | 'full' = 'full';
	export let color: 'white' | 'teal' | 'blue' | 'purple' | 'grey' | 'transparent' | 'red' = 'blue';
	export let img: string | null = null;
	export let extraClasses: string | null = null;
	export let text: string | null = null;
	export let disabled = false;
	export let inactive = false

	function getSize() {
		switch (size) {
			case 'medium':
				return 'w-[272px] p-3 text-caption1';

			case 'small':
				return 'w-full p-3 text-caption1';

			case 'tiny':
				return 'w-full px-3 p-1 text-caption1';

			case 'zero':
				return 'w-full p-1 text-tiny rounded-xl';

			case 'full':
			default:
				return 'w-full p-4 xl:p-7 text-caption5';
		}
	}
</script>

<button
	on:click
	class:pointer-events-none={inactive}
	class={`flex items-center justify-center gap-4  font-bold ${getSize()}
  rounded-2xl bg-${color} hover:bg-${color}-hover cursor-pointer select-none
  ${color === 'transparent' ? 'border border-solid border-border' : 'border-none'}
  ${color === 'transparent' ? 'hover:border-lightBorder' : ''}
  ${color === 'white' ? 'bg-opacity-40' : ''}
	transition-colors disabled:cursor-not-allowed disabled:bg-disabled ${extraClasses}`}
	class:bg-gray={loading}
	{disabled}
	{...$$restProps}>
	{#if img}
		<img class="h-8 w-8" src={img} alt="btn" />
	{/if}
	{#if text}
		{text}
	{/if}
	<span><slot /></span>
	<Spinner busy={loading} />
</button>
